import { useQuery, useReactiveVar } from '@apollo/client'
import { customBrowsePageItemsVar } from '@cache'
import {
  BreadCrumbs,
  Container,
  GolfClubModelCard,
  MobileGolfClubModelSearch,
  QueryResponseHandler,
  SEO
} from '@components'
import { Button, Carousel, Loader } from '@elements'
import { equipment as graphEquipment, golfClubModel as graphqlGolfClubModel } from '@graphql'
import { useBrowsingView, useGraphqlUser, useUrlSearchParams, useWindowSize } from '@hooks'
import GolfBagIcon from '@images/icons/product-page-golfbag.svg'
import DollarSellIcon from '@images/icons/product-page-sell.svg'
import { ProductPage } from '@pages/components'
import { useLocation } from '@reach/router'
import { GolfClubModel, GolfClubType, GolfEquipmentModel, ProductPageItemType, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import '../product.scss'

interface RelatedProducts {
  related: GolfClubModel[] | GolfEquipmentModel[]
  mostViewed: GolfClubModel[] | GolfEquipmentModel[]
}

interface ProductViewProps {
  slug: string
  item: ProductPageItemType
}

type ProductBasicInfoFilters = {
  flex: string | null
  dexterity: string | null
}

const ProductView: React.FC<ProductViewProps> = ({ slug, item }) => {
  const isGolfClubPage = item === 'GolfClubModel'
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { createBrowsingView } = useBrowsingView()
  const { fetchCurrentUser, fetchUserActions } = useGraphqlUser()
  const currentUser = fetchCurrentUser()
  const { search } = useLocation()
  const currentUserActions = fetchUserActions()
  const customBrowsePageItems = useReactiveVar(customBrowsePageItemsVar)

  const {
    getUrlSearchParam: getFlexParam,
    setUrlSearchParam: setFlexParam,
    deleteSearchParam: deleteFlexParam
  } = useUrlSearchParams('flex')
  const { getUrlSearchParam: getDexterityParam, setUrlSearchParam: setDexterityParam } = useUrlSearchParams('dexterity')
  const {
    getUrlSearchParam: getOptionParam,
    setUrlSearchParam: setOptionParam,
    deleteSearchParam: deleteOptionParam
  } = useUrlSearchParams('option')

  const urlSearchParamsFlex = getFlexParam()
  const urlSearchParamsDexterity = getDexterityParam()
  const urlSearchParamsOption = getOptionParam()

  const [currentPage, setCurrentPage] = useState<'buy' | 'sell'>('buy')

  const golfItemQuery = isGolfClubPage
    ? graphqlGolfClubModel.FetchGolfClubModelBySlug
    : graphEquipment.FetchGolfEquipmentModelSlug

  const { data: golfModelData, loading: isLoadingGolfModelData } = useQuery<
    ResponseType<GolfClubModel | GolfEquipmentModel>
  >(golfItemQuery, {
    variables: { slug },
    fetchPolicy: 'cache-first'
  })

  const golfModel = golfModelData?.res
  const categoryName = golfModel?.category.title
  const isCategoryWithoutFlex = categoryName === 'Putters' || categoryName === 'Wedges'
  const userFlex = currentUser?.basicInfo.flex?.toUpperCase()
  const userDexterity = currentUser?.basicInfo.dexterity?.toUpperCase()

  const isSellPage = currentPage === 'sell'

  if (!golfModel && !isLoadingGolfModelData) {
    navigate('/catalogue/')
    return null
  }

  /**
   * Get selected option by URL search
   */
  const getSelectedOpt = (golfModel: GolfEquipmentModel | GolfClubModel) => {
    if ('golfClubTypes' in golfModel) {
      return golfModel.golfClubTypes.find(golfClubType => golfClubType.id === urlSearchParamsOption) || null
    } else if ('golfEquipmentTypes' in golfModel) {
      return (
        golfModel.golfEquipmentTypes.find(golfEquipmentType => golfEquipmentType.id === urlSearchParamsOption) || null
      )
    }
    return null
  }

  /**
   * Add browsing view
   */
  useEffect(() => {
    if (currentUser && golfModel?.id) createBrowsingView(golfModel)
  }, [golfModel?.id])

  /**
   * Delete flex param if isCategoryWithoutFlex = true
   */
  useEffect(() => {
    if (isCategoryWithoutFlex) {
      deleteFlexParam()
    }
  }, [golfModel])

  useEffect(() => {
    const newSearch = new URLSearchParams(search)
    const hasAnySearchParams = !!urlSearchParamsFlex || !!urlSearchParamsDexterity
    if (hasAnySearchParams) {
      if (urlSearchParamsFlex) newSearch.set('flex', isCategoryWithoutFlex ? '' : urlSearchParamsFlex)
      if (urlSearchParamsDexterity) newSearch.set('dexterity', urlSearchParamsDexterity)
    } else {
      if (currentUser) {
        if (userFlex) newSearch.set('flex', isCategoryWithoutFlex ? '' : userFlex)
        if (userDexterity) newSearch.set('dexterity', userDexterity)
      } else {
        newSearch.set('flex', isCategoryWithoutFlex ? '' : 'REGULAR')
        newSearch.set('dexterity', 'RIGHT')
      }
    }
    navigate(location.pathname + '?' + newSearch.toString(), { replace: true })
  }, [golfModel])

  /**
   * Change selected option (golfItemType)
   */
  const handleChangeOption = async (option: GolfClubType | null) => {
    if (option) await setOptionParam(option.id)
    if (!option) await deleteOptionParam()
  }

  const handleChangeFilters = async (filters: ProductBasicInfoFilters) => {
    if (filters.dexterity || filters.dexterity) {
      const hasDexterityChanged = filters.dexterity !== urlSearchParamsDexterity
      const hasFlexChanged = filters.flex !== urlSearchParamsFlex

      if (hasDexterityChanged) await setDexterityParam(filters.dexterity || '')
      if (hasFlexChanged) await setFlexParam(filters.flex || '')
    }
  }

  /**
   * Render loader when loading data
   */
  const renderLoadingData = () => {
    return (
      <div className="product-loading-data">
        <Loader />
        <div className="product-loading-data-label">Loading...</div>
      </div>
    )
  }

  /**
   * Render selling/buying guide info block
   */
  const renderGuideBlock = () => {
    const isUserWasSelling = currentUserActions?.sold
    const isUserWasBuying = currentUserActions?.bought

    if (isSellPage && isUserWasSelling) return null
    if (!isSellPage && isUserWasBuying) return null
    if (!golfModel) return null
    if (isMobileScreen) {
      return (
        <div
          className={'product-guide-block-mobile'}
          style={{ backgroundImage: 'url(/assets/images/mobile-guide-golfclubs.webp)' }}
        >
          <div className={'product-guide-block-mobile-title'}>{isSellPage ? 'Selling guide' : 'Buying guide'}</div>
          <div className={'product-guide-block-mobile-button'}>
            <Button to={isSellPage ? '/about/selling/' : '/about/buying'} type="roundBlack">
              Read more
            </Button>
          </div>
        </div>
      )
    }
    return (
      <div className={'product-guide-block'}>
        <div className={'product-guide-block-image'}>
          <img src="/assets/images/product-guide-clubs.webp" alt="guide_clubs" />
        </div>
        <div className={'product-guide-block-info'}>
          <div className={'product-guide-block-info-title'}>{isSellPage ? 'Selling Guide' : 'Buying Guide'}</div>
          <div className={'product-guide-block-info-subtitle'}>
            {isSellPage
              ? 'Search through the extensive TourDay Golf catalog of golf equipment to find the club you want to sell.'
              : 'Search through the extensive TourDay Golf catalog of golf equipment to find the club you want to buy.'}
          </div>
          <div className={'product-guide-block-info-button'}>
            <Button to={isSellPage ? '/about/selling/' : '/about/buying'} type="roundBlack">
              Read more
            </Button>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render Related products carousel
   */
  const renderRelatedProducts = () => {
    const relatedGolfItemsQuery = isGolfClubPage
      ? graphqlGolfClubModel.FetchRelatedGolfClubModels
      : graphEquipment.FetchRelatedEquipments
    const relatedGolfItemsPayload = isGolfClubPage
      ? { golfClubModelId: golfModel?.id }
      : { golfEquipmentModelId: golfModel?.id }

    return (
      <QueryResponseHandler<RelatedProducts> query={relatedGolfItemsQuery} variables={relatedGolfItemsPayload}>
        {({ related, mostViewed }) => {
          return (
            <>
              {/* related */}
              <div className={'product__related'}>
                {Boolean(related.length) && (
                  <Carousel
                    breakPoints={[
                      { width: 500, itemsToShow: 2.3 },
                      { width: 770, itemsToShow: 3.3 }
                    ]}
                    title={'Related products'}
                    list={related}
                    pagination={false}
                    Component={({ listData }) => (
                      <div className="product__related-product-card">
                        <GolfClubModelCard golfModel={listData} />
                      </div>
                    )}
                  />
                )}
              </div>
              {/* most viewed */}
              <div className={'product__most-viewed'}>
                {Boolean(mostViewed.length) && (
                  <Carousel
                    breakPoints={[
                      { width: 500, itemsToShow: 2.3 },
                      { width: 770, itemsToShow: 3.3 }
                    ]}
                    title={'People also viewed'}
                    list={mostViewed}
                    pagination={false}
                    Component={({ listData }) => (
                      <div className="product__related-product-card">
                        <GolfClubModelCard golfModel={listData} />
                      </div>
                    )}
                  />
                )}
              </div>
            </>
          )
        }}
      </QueryResponseHandler>
    )
  }

  const renderCustomBrowsePageItems = () => {
    if (!customBrowsePageItems.entries.length) return null
    const catalogItemsWithoutCurrentModel = customBrowsePageItems.entries.filter(
      catalogItem => catalogItem.id !== golfModel?.id
    )
    {
      /* custom browse page items */
    }
    return (
      <div className={'product__custom-browse'}>
        <Carousel
          titleLink={customBrowsePageItems.backUrl}
          breakPoints={[
            { width: 500, itemsToShow: 2.3 },
            { width: 770, itemsToShow: 3.3 }
          ]}
          title={customBrowsePageItems.title}
          list={catalogItemsWithoutCurrentModel}
          pagination={false}
          Component={({ listData }) => (
            <div className="product__related-product-card">
              <GolfClubModelCard golfModel={listData} />
            </div>
          )}
        />
      </div>
    )
  }

  const breadCrumbs = [golfModel?.category.title, golfModel?.brand.title, golfModel?.title].join(' / ')

  return (
    <section className="product">
      <SEO title={breadCrumbs} />
      {isMobileScreen && (
        <div className={'product-mobile-search-panel'}>
          <div className={'product-mobile-search-panel-autocomplete'}>
            <MobileGolfClubModelSearch />
          </div>
          <div data-sell={isSellPage} className={'product-buying-info_mode'}>
            {isSellPage ? <DollarSellIcon /> : <GolfBagIcon />}
            {isSellPage ? 'You are in the SELL mode' : 'You are in the BUY mode'}
          </div>
        </div>
      )}
      {golfModel ? (
        <Container withCard={!isMobileScreen}>
          <div className="product-view">
            {!isMobileScreen && (
              <ProductPage.BasicInfo
                golfModel={golfModel}
                BreadCrumbs={<BreadCrumbs golfModel={golfModel} />}
                handleChangeFilters={handleChangeFilters}
                filters={{ flex: urlSearchParamsFlex, dexterity: urlSearchParamsDexterity }}
              />
            )}

            <ProductPage.BuyingInfo
              itemType={item}
              currentPage={currentPage}
              BreadCrumbs={<BreadCrumbs golfModel={golfModel} />}
              setCurrentPage={setCurrentPage}
              golfModel={golfModel}
              handleSelectOption={handleChangeOption}
              selectedOption={getSelectedOpt(golfModel)}
              filters={{ flex: urlSearchParamsFlex, dexterity: urlSearchParamsDexterity }}
              isSellPage={currentPage === 'sell'}
              handleChangeFilters={handleChangeFilters}
            />
          </div>
          {renderRelatedProducts()}
          {renderCustomBrowsePageItems()}
          {renderGuideBlock()}
        </Container>
      ) : (
        renderLoadingData()
      )}
    </section>
  )
}

export default memo(ProductView)
